<template>
  <div class="container-fluid mt-5" v-if="data">
    <div class="row">
      <h3>請點選地區</h3>
    </div>
    <div
      class="
        row
        wrap
        justify-content-center
        align-items-center
        flex-column
        mt-5
      "
    >
      <router-link
        :to="{ path: `/chooseProject` }"
        class="col-12 box mb-3"
        ><div style="font-size: 1.1rem;">大陸</div></router-link
      >
    </div>
    <div
      class="
        row
        wrap
        justify-content-center
        align-items-center
        flex-column
        mt-5
      "
    >
      <router-link
        :to="{ path: `/chooseROC` }"
        class="col-12 box mb-3"
        ><div style="font-size: 1.1rem;">中華、公班</div></router-link
      >
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  name: "ChooseProject2",
  setup() {
    const data = ref(null);
    async function getData() {
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}type=GETNA1`
      ).catch((err) => console.log(err));
      const result = await res.json();
      // console.log(result);
      if (result.data) {
        return (data.value = result.data);
      }
    }
    onMounted(async () => {
      await getData();
    });
    return {
      getData,
      data,
    };
  },
};
</script>
<style scoped>
.box {
  width: 250px;
  min-height: 65px;
  border: 1px solid var(--input-line);
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: initial;
}
.box > div {
  width: 80%;
  margin: 20px auto;
}
.box:focus,
.box:active {
  border: 1px solid var(--first-color);
  color: var(--first-color);
}
</style>